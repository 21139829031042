<template>
  <v-container id="lock" class="fill-height" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                {{ $t("session_expired") }}
              </h1>
            </div>
          </template>

          <base-material-alert
            v-if="$store.state.auth.errorLogin"
            color="error"
            dark
            >{{ $t("invalid-credentials") }}</base-material-alert
          >

          <form
            action
            data-vv-scope="unlock-form"
            @submit.prevent="validateForm('unlock-form')"
          >
            <v-card-text class="text-center">
              <div
                class="display-2 font-weight-light mb-3 text--darken-2 grey--text"
              >
                {{ $store.state.auth.user.name }}
              </div>

              <v-text-field
                outlined
                dense
                v-model="password"
                data-vv-validate-on="blur"
                v-validate="'required|min:6'"
                type="password"
                :error-messages="errors.collect('unlock-form.password')"
                data-vv-name="password"
                class="mb-8"
                color="secondary"
                :label="$t('password')"
                prepend-icon="mdi-lock-outline"
              />

              <pages-btn
                large
                color
                depressed
                class="v-btn--text success--text"
                type="submit"
                >{{ $t("unblock") }}</pages-btn
              >
            </v-card-text>
          </form>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PagesLock",

  components: {
    PagesBtn: () => import("./components/Btn"),
  },

  data: () => ({
    password: "",
  }),

  methods: {
    validateForm(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.$store
            .dispatch("auth/authenticate", {
              email: this.$store.state.auth.user.email,
              password: this.password,
            })
            .then(() => {
              if (!this.$store.state.auth.errorLogin) {
                // window.location.href = `/#${this.$route.query.redirect}`;
                window.location.href = "/";
              } else {
              }
            });
        }
      });
    },
  },
};
</script>
